<template>
  <div id="EditProperty">
    <b-row>
      <b-col sm="8">
        <div class="card">
          <div slot="header" class="card-header">
            <i class="fa fa-edit"></i> {{ headline }}

          </div>
          <div v-if="property" class="card-body">
            <b-form v-on:submit.prevent="onSubmit">
              <b-form-group validated>
                <label for="name">Label</label>
                <b-form-input type="text" id="name" required aria-required="true" v-model="property.label"
                              placeholder="Bezeichnung"></b-form-input>
              </b-form-group>
              <b-form-group>
                <label>Bild</label><br>
                <div class="margin-bottom-10">
                  <img v-if="property.image && property.image.id > 0"
                       v-bind:src="$store.state.mediaServiceUrl + property.image.uid + '/200/200'"
                       class="image-placeholder-150">
                </div>
                <b-button size="sm" @click="openMediaSelection('image')" variant="dark"><i class="fa"></i>Bild
                  auswählen/ändern
                </b-button>&nbsp;
                <a class="fa fa-trash fa-lg text-primary" v-if="property.image && property.image.id"
                   @click="deleteMedia('image')"></a>
              </b-form-group>
              <br>
              <div class="footer-btn" slot="footer">
                <b-button type="submit" size="sm" variant="primary"><i class="fa fa-save"></i> Speichern</b-button>
                <b-button size="sm" :href="'/#/property/properties/' + $route.params.type " variant="danger"><i
                  class="fa fa-ban"></i>Abbrechen
                </b-button>
              </div>
            </b-form>
          </div>
          <br>
        </div>
      </b-col>
      <MediaSelection
        :show="showMediaSelection"
        :type="mediaType"
        :mime-types="'image/jpeg,image/png'"
        @close="showMediaSelection = false"
        @save="saveMedia($event)"
      />
    </b-row>
  </div>
</template>

<script>

  import MediaSelection from '../Media/MediaSelection'
  import {mapGetters} from 'vuex'
  import VueNotifications from 'vue-notifications'

  const axios = require('axios')

  export default {
    components: {
      MediaSelection,
    },
    name: 'editProperty',
    data() {
      return {
        headline: '',
        showMediaSelection: false,
        mediaType: '',
      }
    },
    computed: {
      ...mapGetters({
        property: 'poi/property/getProperty',
        media: 'media/getMedia',
        error: 'poi/property/getError'
      })
    },
    mounted() {
      if (this.$route.params.id > 0) {
        this.$store.dispatch('poi/property/loadProperty', this.$route.params.id)
        this.headline = this.getTypeLabel() + ' bearbeiten'
      } else {
        this.$store.dispatch('poi/property/initProperty')
        this.headline = this.getTypeLabel() + ' anlegen'
      }
    },
    methods: {
      openMediaSelection: function (mediaType) {
        this.showMediaSelection = true
        this.mediaType = mediaType
      },
      saveMedia: function (payload) {
        this.property.image = {'id': payload.id, 'uid': payload.uid}
      },
      deleteMedia: function (mediaType) {
        this.property.image = undefined;
      },
      onSubmit() {
        this.$store.dispatch('poi/property/saveProperty', {
          id: this.$route.params.id,
          type: this.$route.params.type,
          label: this.property.label,
          image: this.property.image ? this.property.image.id : ''
        }).then(response => {
          this.notifyTemplate({
            type: VueNotifications.types.success,
            title: 'Erfolgreich gespeichert'
          })
          this.$router.replace(this.$route.query.redirect || '/property/properties/' + this.$route.params.type)
        }, error => {
          this.notifyTemplate({
            type: VueNotifications.types.error,
            title: 'Fehler beim Speichern: ' + error
          })
        })
      },
      getTypeLabel() {
        switch (this.$route.params.type) {
          case 'tags':
            return 'Tag'
          case 'graduation':
            return 'Schulabschluss'
          case 'department':
            return 'Fachbereich'
          case 'branch':
            return 'Branch'
          case 'begin-period':
            return 'Zeitraum'
        }
      }
    },
    notifications: {
      notifyTemplate: {},
    }
  }
</script>
